<template>
  <v-container fluid>
        <v-row>
            <v-col cols="12">
                <div class="d-flex">
                    <v-row>
                        <v-col cols="12">
                            <div class="d-flex">
                                <breadcrumb :breadcumbs="breadcumbs"></breadcrumb>
                                <v-spacer></v-spacer>
                            </div>
                        </v-col>
                    </v-row>
                    <!-- <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                                >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs> -->
                     <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
              <v-col>
                    <v-card color="blue lighten-5">
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-autocomplete solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="office" 
                                        :items="offices" 
                                        default="" 
                                        item-value="office_id" 
                                        item-text="office" 
                                        label="Office ID"
                                        >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-autocomplete solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="docid" 
                                        :items="menu_headerss" 
                                        default="" 
                                        item-value="doc_id" 
                                        item-text="doc_id" 
                                        label="DOC ID" 
                                        clearable 
                                        >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-autocomplete solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="remark" 
                                        :items="menu_headerss" 
                                        default="" 
                                        item-value="remark" 
                                        item-text="remark" 
                                        label="Remark" 
                                        clearable  
                                        >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-autocomplete solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="tr_type" 
                                        :items="types" 
                                        default="" 
                                        item-value="value" 
                                        item-text="status" 
                                        label="Tr_type" 
                                        clearable  
                                        >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        clearable 
                                        v-model="date_from"
                                        label="Date From"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                               <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-menu
                                    ref="modal"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_to"
                                        clearable
                                        label="Date to"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                    </v-menu>
                                </v-col>    
                                <v-row class="align-center mt-0 ma-0 pa-2">                            
                                <v-col cols="12" xs="12" sm="3" md="1">
                                    <v-btn class="mt-0 ml-1" 
                                    color="info" elevation="2" block large @click="getPullData()">Search</v-btn>
                                </v-col>
                                </v-row>
                                 <v-row class="mt-2" style="padding-bottom:50px">
                                    <v-col cols="12">
                                        <v-card class="rounded-l elevation-5 ml-3" color="blue lighten-5">
                                            <v-card-text>
                                                <h5>Result</h5>
                                                <v-row justify="end">
                                                  <v-col cols="12" xs="12" sm="12" md="2" class="text-right">
                                                     <v-btn class="mb-4 mr-2 text-white" 
                                                        color="#0D47A1" elevation="2" medium>
                                                        <download-excel
                                                        class="text"
                                                        :fetch           = "exportExcel"
                                                        :fields="headersColumn"
                                                        :before-generate = "startDownload"
                                                        :before-finish   = "finishDownload">
                                                        Export Excel
                                                        </download-excel> 
                                                     <v-icon>mdi-arrow-down-bold-box</v-icon>
                                                    </v-btn>
                                                </v-col>
                                                 </v-row>
                                                <v-data-table
                                                    dense
                                                    :headers="headers"
                                                    :items="datas"
                                                    :loading="loading"
                                                    loading-text="Please wait, retrieving data"
                                                    class="elevation-1"
                                                    > 
                                            <template v-slot:[`item.actions`]="{ item }">
                                            <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>Show</span>
                                            </v-tooltip>
                                            </template> 
                                                </v-data-table>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-dialog v-model="dialog" max-width="1200px">                
                                <v-card>
                                    <v-card-text>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-card-title>Detail Item
                                                        <v-spacer></v-spacer>
                                                    </v-card-title>
                                                    <v-data-table
                                                        :headers="headerss"
                                                        :items="details"
                                                        :loading="loading3"
                                                        loading-text="Please wait, retrieving data"
                                                        page-count="10"
                                                        class="elevation-1"
                                                        > 
                                                    </v-data-table>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
        </v-row>
  </v-container>
</template>

<script>
    import Breadcrumb from "@/components/Breadcrumb.vue"

    export default {
    components: {
        Breadcrumb
    },
    data(){
        return {
            loading: false,
                snackbar: {
                    color: null,
                    icon: null,
                    mode: null,
                    position: "top",
                    text: null,
                    timeout: 7500,
                    title: null,
                    visible: false
                },
                breadcumbs: [
                    {
                    text: 'Griya Kusuma',
                    disabled: false,
                    href: '/admin/gk',
                    },
                    {
                    text: 'Information',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                    {
                    text: 'Income & Cost List',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                ],
                datas:[],
                menu_headerss:[],
                headers:[
                    {
                    text: 'Office ID',
                    align: 'start',
                    sortable: true,
                    value: 'office_id', 
                    align:'center'},
                    { text: 'Inv ID', value: 'inv_id' , align:'center'},
                    { text: 'Doc ID', value: 'doc_id' , align:'center'},
                    { text: 'Tr Type', value: 'tr_type', align: 'center', visible: false },
                    { text: 'Date From', value: 'date_from' , align:'center'},
                    { text: 'Remark', value: 'remark' , align:'left'},
                    { text: 'Total Masuk', value: 'total_masuk', align: 'center', visible: false },
                    { text: 'Total Keluar', value: 'total_keluar', align: 'center', visible: false },
                    { text: 'Detail',  value: 'actions', sortable: false },
                ],
                headersColumn: {
                'Office ID':'office_id',
                'Invoice id': 'inv_id',
                'Document ID': 'doc_id',
                'Tr Type' : 'tr_type',
                'Periode': 'period',
                'Remark': 'remark',
                'Date From': 'date_from',
                'Date To': 'date_to',
                'Total Masuk': 'total_masuk',
                'Total Keluar': 'total_keluar',
                },
                offices: [{
                office_id : 'Griya Kusuma Indah',
                office : 'Griya Kusuma Indah'
                }],
                office: 'Griya Kusuma Indah',
                types: [
                {
                    status: 'INCOME',
                    value: 'I'
                },
                {
                    status: 'COST',
                    value: 'O'
                }

                ],
                details:[],
                headerss:[
                    {
                    text: 'Office ID',
                    align: 'start',
                    sortable: true,
                    value: 'office_id', 
                    align:'center'},
                    { text: 'Item', value: 'item' , align:'left'},
                    { text: 'Tr Type', value: 'tr_type', align: 'center'},
                    { text: 'Period', value: 'period' , align:'left'},
                    { text: 'Date From', value: 'date_from' , align:'left'},
                    { text: 'Date To', value: 'date_to' , align:'left'},
                    { text: 'Total Masuk', value: 'total_masuk' , align:'left'},
                    {text: 'Total Keluar', value: 'total_keluar' , align:'left'},
                ],
                //offices:[],
               // office:'',
                datas:[],
                docid:'',
                remark:'',
                tr_type:'',
                date_from: '',
                date_to: '',
                modal_to:'',
                modal: false,
                loading3: false,
                dialog: false,
                modal2: false,    
        
            }
            
        },
    mounted(){
    this.GetIndex()

    },
    methods:{
    close() {
        this.dialog = false
    },
    startDownload(){
           this.loading = true
           this.disabledExport=true
        },
    finishDownload(){
        this.loading = false
        this.disabledExport=false
    },
    showItem(item){
        console.log(item)
        this.office_id = item.office_id
        this.inv_id = item.inv_id
        this.doc_id = item.doc_id
        this.period = item.period
        this.item = item.item
        this.tr_type = item.tr_type
        this.total_keluar = item.total_keluar
        this.total_masuk = item.total_masuk
        this.date_from = item.date_from
        this.date_to = item.date_to
        this.dialog = true
        this.getDetail()
        
    },  
    async exportExcel() {
        this.loading = true;
        this.$store.dispatch('setOverlay', true);
        if (this.office === '' || this.date_from === '') {
            this.snackbar = {
            color: "error",
            icon: "mdi-checkbox-marked-circle",
            mode: "multi-line",
            position: "top",
            timeout: 7500,
            title: "Error",
            text: "Please Input Start Date",
            visible: true
            };
            this.$store.dispatch('setOverlay', false);
            this.loading = false;
        } else {
            var startDate = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g, "") : '';
            var endDate = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g, "") : new Date().toISOString().substr(0, 10).replace(/-/g, "");
            const response = await axios.get(`${process.env.VUE_APP_URL}/api/griya/daily_trans/GetData?search=${this.$store.state.text}&doc_id=${this.docid ? this.docid : ''}&remark=${this.remark ? this.remark : ''}&tr_type=${this.tr_type ? this.tr_type : ''}&start_date=${startDate}&end_date=${endDate}`, {
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
            });
            const transformedData = response.data.data.map(item => {
            this.$store.dispatch('setOverlay', false);
            this.loading = false;
            if (item.tr_type === 'I') {
                item.total_keluar = '';
            } else if (item.tr_type === 'O') {
                item.total_masuk = '';
            }
            return item;
            });
            return transformedData;
        }

    },     
    async GetIndex(){
        await axios.get(`${process.env.VUE_APP_URL}/api/griya/daily_trans`, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
            this.menu_headerss = res.data.data
        })
            
    },    
    async getPullData() {
        this.loading = true;
        this.$store.dispatch('setOverlay', true);
        if (this.office === '' || !this.date_from) {
            this.loading_dialog = false;
            this.$store.dispatch('setOverlay', false);
            this.snackbar = {
                color: "error",
                icon: "mdi-checkbox-marked-circle",
                mode: "multi-line",
                position: "top",
                timeout: 7500,
                title: "Error",
                text: "Please Input Start Date",
                visible: true
            };
        } else {
            this.loading = false;
            var startDate = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g, "") : '';
            var endDate = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g, "") : new Date().toISOString().substr(0, 10).replace(/-/g, "");
            await axios.get(`${process.env.VUE_APP_URL}/api/griya/daily_trans/GetData?search=${this.$store.state.text}&doc_id=${this.docid ? this.docid : ''}&remark=${this.remark ? this.remark : ''}&tr_type=${this.tr_type ? this.tr_type : ''}&start_date=${startDate}&end_date=${endDate}`, {
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
            })
            .then(res => {
                this.$store.dispatch('setOverlay', false);
                this.loading = false;

                this.datas = res.data.data.map(item => {
                    if (item.tr_type === 'I') {
                        item.total_keluar = ''; 
                    } else if (item.tr_type === 'O') {
                        item.total_masuk = ''; 
                    }
                    return item;
                });
            });
        }
    },
   async getDetail(){
            await axios.get(`${process.env.VUE_APP_URL}/api/griya/daily_trans/GetDetail?search=${this.$store.state.text}&office_id=${this.office_id ? this.office_id : ""}&doc_id=${this.doc_id ? this.doc_id : ''}&inv_id=${this.inv_id ? this.inv_id : ''}&period=${this.period ? this.period : ''}&tr_type=${this.tr_type ? this.tr_type : ''}&date_from=${this.date_from ? this.date_from : ''}&date_to=${this.date_to ? this.date_to : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.details = res.data.data
            })
        },  

    },    
   

    }
</script>

    <style>

    </style>